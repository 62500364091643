import { DEFAULT_LANGUAGE } from '@incarail/killa-common';
import { ContentProps } from '@/types';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { BaseLayout } from '@/components';
import { getLocation, getNavigation, getPage, getPageUrls, getSettings } from '@/utils'

type PageUrls = {
  [key: string]: string;
};

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  const { locale, preview, req, query } = context;
  const { forceCode, abtId } = query;

  try {
    const currentIP = req.headers['x-real-ip'] as string;
    const slug = 'home'; // default home slug at contentful
    // Connect to IPINFO and retrieve location
    // const { country } = await getLocation(process.env.IPINFO_TOKEN, currentIP);

    const page = await getPage(slug, locale ?? DEFAULT_LANGUAGE, context, 'All');

    if (page) {
      const { fields: { metaConfig, contents } } = page; 

      const pageContents = abtId 
        ? contents?.find(content => content.sys.id === abtId)?.fields.contents || contents?.[0]?.fields.contents || null
        : contents?.[0]?.fields.contents || null;

      const pageSettings = abtId 
        ? contents?.find(content => content.sys.id === abtId)?.fields.settings || contents?.[0]?.fields.settings || null
        : contents?.[0]?.fields.settings || null;

      const navigation = await getNavigation(
        pageSettings?.fields.headerConfig?.mainNavigation?.slug ?? 'main',
        locale ?? DEFAULT_LANGUAGE, 
        context
      );

      const topNavigation = await getNavigation(
        pageSettings?.fields.headerConfig?.topNavigation?.slug ?? 'top',
        locale ?? DEFAULT_LANGUAGE, 
        context
      );

      const infoNavigation = await getNavigation(
        pageSettings?.fields.headerConfig?.infoNavigation?.slug ?? 'info',
        locale ?? DEFAULT_LANGUAGE,
        context
      );

      let pageUrls = await getPageUrls(page.sys.id, context);
      const settings = pageSettings ?? await getSettings('default', locale ?? DEFAULT_LANGUAGE, context);

      const enabledLocales = settings?.fields?.localeConfig?.locale
      .filter((locale: { enabled: boolean }) => locale.enabled)
      .map((locale: { abbr: string }) => locale.abbr) ?? [];

      pageUrls = Object.keys(pageUrls)
      .filter((key) => enabledLocales.includes(key))
      .reduce((obj: PageUrls, key) => {
        obj[key] = pageUrls[key];
        return obj;
      }, {} as PageUrls);

      const { fields: { footerConfig } } = settings;
      
      if(footerConfig?.navigation) {
        for(const footerNavigation of footerConfig.navigation) {
          const content = await getNavigation(footerNavigation.name, locale ?? DEFAULT_LANGUAGE, context);
          footerNavigation.content = content;
        }    
      }

      // Return content to page
      return {
        props: {
          ...(await serverSideTranslations(locale ?? DEFAULT_LANGUAGE, [
            'common',
            'geo',
            'form',
            'prices',
            'travelPackages',
            'breadcrumb',
            'counterdown',
            'layout',
            'navigation',
            'trainSchedule',
          ])),
          metaConfig,
          navigation,
          topNavigation,
          infoNavigation,
          settings,
          pageContents,
          pageUrls,
          preview: preview ?? false,
          locale,
          slug,
          isLocal: false,
          abtId: abtId ?? null
        },
      };

    }
    else {
      return {
        redirect: {
          destination: `/${locale ?? DEFAULT_LANGUAGE}`,
          permanent: true,
        },
      };
    }   

  } catch (err) {
    console.log('err', err);
    return {
      redirect: {
        destination: `/${locale ?? DEFAULT_LANGUAGE}`,
        permanent: true,
      },
    };
}
};

export default function IndexPage(props: Readonly<ContentProps>) {
  return <BaseLayout {...props} />;
}
